//подключение apx и tailwind
@import '../../../../styles/_apx.scss';

.inner-window {

  height: auto;
  min-width: 180px;

  svg {
  
    width: 100%;
    height: auto;
  
  }

  .window_inner_textfield {
  
    top: 0;
    width: 60px;
    position: absolute;

      &--X {
      
        top: -33px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
    
      }

      &--Y {
      
        top: 50px;
        left: -70px;
        text-align: right;
    
      }

    &__text {
    
      z-index: 3;
      width: 100%;
      color: #7ABB5C;
      font-family: Roboto;
      font-size: 22px;
      line-height: 24px;
      font-weight: 500 !important;
      
    }

    &__input {
    
      z-index: 3;
      margin-top: -1px;
      position: absolute;
      background-color: unset;
      outline: none !important;
      width: 100%;
      border: unset;
      font-family: Roboto;
      color: #7ABB5C;
      font-size: 22px;
      line-height: 24px;
      font-weight: 500 !important;

      &--X {

        left: 0px;
        text-align: center;
        color: #7ABB5C !important;
        &::placeholder { color: #7ABB5C !important; }
      
      }

      &--Y {

        left: 2px;
        text-align: right;
        color: #7ABB5C !important;
        &::placeholder { color: #7ABB5C !important; }
      
      }

    }
    
  }
  
}

//адаптив mobile
@media screen and ( max-width: 800px ) {

  .inner-window {

    width: 135px;
  
    .window_inner_textfield {
  
      width: 80px;

      &--X {
      
        top: -36px;
        left: -20px;
      
      }

      &--Y {
      
        top: 50px;
        left: -105px;
    
      }
  
      &__text {
      
        width: 100px;
        height: 65px;
        font-size: 26px;
        line-height: 30px;
        
      }
  
      &__input {
      
        width: 100px;
        height: 65px;
        font-size: 26px;
        line-height: 30px;
        margin-top: -18px;
        
        &--X {

          left: 0;
          text-align: center;

        }
      
      }
      
    }

  }

}