.balkon_settings {

  height: 100%;
  overflow-y: auto;
  padding: 0 16px 16px;
  padding-bottom: 58px;
  background: #fffffff0;

  &__title {
    
    width: calc( 100% + 32px );
    margin-left: -16px;
    font-weight: 500;
    font-size: 21px;
    line-height: 21px;
    color: #565656;
    padding-top: 14px;
    padding-left: 16px;
    padding-bottom: 14px;
    margin-bottom: 14px;
    // flex-grow: 1;
    background: #fffcfbf0;
    border: 2px solid #f3f3f3;
    border-left: unset;
    border-right: unset;

  }

  .phone_btn {

    .button {
      
      min-width: unset;
      padding-left: 16px;
      padding-right: 16px;
      width: unset;
      margin: unset;
      margin-top: 2px;
      margin-left: -32px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    
    }

  }

  .extracharge {

    flex-wrap: wrap;

    &__input { white-space: nowrap; }

    .textfield {

      max-width: 75px;
      margin-bottom: unset;

      &__title, input { color: rgb(12, 150, 12); }

      input { 
        
        font-size: 18px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-right: 6px;
        padding-left: 3px;
        font-weight: 600;
        text-align: right; 
        color: #06ac00;
      
      }

    }

    .percent {

      padding-top: 21px;
      margin-left: 3px;
      font-size: 26px;
      font-weight: 600;
      color: #06ac00;

    }

  }

  .price_preview {

    white-space: nowrap;
    font-size: 22px;
    padding-top: 27px;
    padding-bottom: 6px;
    padding-left: 10px;
    color: #06ac00;
    font-weight: 600;

    &__sym { padding-right: 10px; }

    &__details {

      white-space: nowrap;
      font-size: 17px;
      padding-top: 10px;
      padding-bottom: 14px;
      padding-left: 10px;
      color: #06ac00;
      font-weight: 500;

      &__text { padding-right: 10px; }

      &__tip { 
        
        font-style: italic;
        font-size: 14px;
        padding: 0px 10px 10px;
        color: rgb(139, 139, 139);
      
      }

    }
  
  }

}