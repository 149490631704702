.toggleslider {

  width: 100%;
  user-select: none;

  &__title {

    font-size: 16px;
    font-weight: 400;
    color: #383838;
    margin-bottom: 8px;

  }

  &__container {

    width: 100%;
    height: 34px;
    margin-bottom: 8px;
    background-color: #dfdfdf;
    border-radius: 7px;
    cursor: pointer;

  }

  &__list {

    top: 0;
    width: 100%;
    height: 100%;

    z-index: 3;

    &__element {

      height: 100%;
      font-size: 16px;
      font-weight: 500;
      color: #BDBDBD;

      &--selected {

        color: #fff;

      }

    }

  }

  &__anchor {

    z-index: 2;
    top: 0;
    height: 100%;
    background-color: #7ABB5C;
    border-radius: 7px;
    transition: 0.3s;

  }

}