.container { 
  
  display: flex;
  padding: calc( 60px - ( ( 60px / 100 ) * var( --dpi-zoom, 1 ) ) ) calc( 10px - ( ( 10px / 100 ) * var( --dpi-zoom, 1 ) ) ) calc( 10px - ( ( 10px / 100 ) * var( --dpi-zoom, 1 ) ) );
  overflow-x: hidden;

  hr {

    display: block;
    width: calc( 100% + 20px);
    height: 1px;
    background-color: #EDEDED;
    border: unset;
    margin: calc( 11px - ( ( 11px / 100 ) * var( --dpi-zoom, 1 ) ) ) 0;
    margin-left: calc( -10px - ( ( -10px / 100 ) * var( --dpi-zoom, 1 ) ) );

  }

}