@import '../../../styles/_apx.scss';

.windows_inner_schema {

  &-1 {

    min-width: $apx-125;
    height: $apx-101;

  }

  &-2 {
      
    min-width: $apx-106;
    height: $apx-100;
    
  }

  &-3 {
    
    min-width: $apx-100;
    height: $apx-100;
    
  }

  svg {

    & + svg { margin-left: $apx-22; }

  }

}

//адаптив mobile
@media screen and ( max-width: 800px ) {

  .windows_inner_schema {

    &-1 {
  
      height: auto;
      min-width: $apx-m-95;
  
    }
  
    &-2 {

      height: auto;
      min-width: $apx-m-75;
      
    }
  
    &-3 {

      height: auto;
      min-width: $apx-m-80;
      
    }
  
    svg {
  
      & + svg { margin-left: $apx-m-25; }
  
    }
  
  }

}