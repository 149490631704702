.steps {

  &__title {

    font-size: calc( 18px - ( ( 18px / 100 ) * var( --dpi-zoom, 1 ) ) );
    font-weight: 500;
    color: #6F6F6F;

  }

  &__element {

    position: relative;
    width: calc( 28px - ( ( 28px / 100 ) * var( --dpi-zoom, 1 ) ) );
    height: calc( 28px - ( ( 28px / 100 ) * var( --dpi-zoom, 1 ) ) );
    font-size: calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ) );
    font-weight: 900;
    color: #7ABB5C;
    border-radius: 100px;
    border: calc( 3px - ( ( 3px / 100 ) * var( --dpi-zoom, 1 ) ) ) solid #7ABB5C;

    &--active {

      background: #7ABB5C;
      color: #fff;

    }

    &:not(&:first-child) {

      margin-left: calc( 20px - ( ( 20px / 100 ) * var( --dpi-zoom, 1 ) ) );

      &::before {

        content: "";
        display: block;
        position: absolute;
        left: calc( -23px - ( ( -23px / 100 ) * var( --dpi-zoom, 1 ) ) );
        width: calc( 23px - ( ( 23px / 100 ) * var( --dpi-zoom, 1 ) ) );
        height: calc( 2px - ( ( 2px / 100 ) * var( --dpi-zoom, 1 ) ) );
        background: #7ABB5C;


      }

    }

  }

}