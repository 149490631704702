.picker_texture {

  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: -150vw;
  width: calc( 310px - ( ( 310px / 100 ) * var( --dpi-zoom, 1 ) ) );
  height: 100%;
  padding: calc( 22px - ( ( 22px / 100 ) * var( --dpi-zoom, 1 ) ) );
  transition: 0.4s;
  overflow-y: auto;
  padding-bottom: 100px;
  background: #ffffffe8;

  &--opened {

    left: 0;

  }

  &__title {

    font-weight: 700;
    font-size: calc( 20px - ( ( 20px / 100 ) * var( --dpi-zoom, 1 ) ) );
    color: #6F6F6F;
    margin-bottom: 20px

  }

  &__list {

    bottom: 0;
    width: 100%;
    z-index: 2;

    & + .picker_texture__list { margin-top: calc( 20px - ( ( 20px / 100 ) * var( --dpi-zoom, 1 ) ) ); }

    &__inner {

      padding-top: calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ) );
      padding-left: calc( 30px - ( ( 30px / 100 ) * var( --dpi-zoom, 1 ) ) );
      padding-bottom: calc( 20px - ( ( 20px / 100 ) * var( --dpi-zoom, 1 ) ) );

    }

    &__item {

      cursor: pointer;
      user-select: none;
      transition: 0.4s;
      transform-origin: left;

      & + .picker_texture__list__item { margin-top: calc( 20px - ( ( 20px / 100 ) * var( --dpi-zoom, 1 ) ) ); }
      &:hover { transform: scale( 1.05 ); }

      &__name {

        font-size: calc( 15px - ( ( 15px / 100 ) * var( --dpi-zoom, 1 ) ) );
        font-weight: 400;
        color: #494545;

      }

      &__material {

        cursor: pointer;
        transition: 0.1s ease-in;
        height: calc( 50px - ( ( 50px / 100 ) * var( --dpi-zoom, 1 ) ) );
        min-width: calc( 50px - ( ( 50px / 100 ) * var( --dpi-zoom, 1 ) ) );
        width: calc( 50px - ( ( 50px / 100 ) * var( --dpi-zoom, 1 ) ) );
        max-width: calc( 50px - ( ( 50px / 100 ) * var( --dpi-zoom, 1 ) ) );
        border: calc( 4px - ( ( 4px / 100 ) * var( --dpi-zoom, 1 ) ) ) solid #f4f4f4;
        border-radius: calc( 10px - ( ( 10px / 100 ) * var( --dpi-zoom, 1 ) ) );
        // box-shadow: 0px calc( 4px - ( ( 4px / 100 ) * var( --dpi-zoom, 1 ) ) ) calc( 10px - ( ( 10px / 100 ) * var( --dpi-zoom, 1 ) ) ) rgba(0, 0, 0, 0.30);
        background-size: cover;
        background-position: center;
        margin-right: calc( 17px - ( ( 17px / 100 ) * var( --dpi-zoom, 1 ) ) );

        &__rotation {

          padding-left: 26px;
          padding-top: 5px;

          .picker_texture__list__item:last-child { margin-bottom: 10px; }
          .picker_texture__list__item + .picker_texture__list__item { margin-top: 4px; }

        }
              
      }
    
    }

  }

}