.cbar_container {

  position: fixed;
  z-index: 4;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 364px;
  height: 56px;

}

.cbar {

  position: relative;
  z-index: 4;
  width: 100%;
  height: 100%;
  padding: 2px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #C8C8C8;
  box-sizing: border-box;
  border-radius: 16px 16px 0px 0px;
  user-select: none;
  cursor: pointer;

  &__item {

    min-width: 49px;
    max-width: 49px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__text {

      font-weight: 500;
      font-size: 10px;
      text-align: center;
      color: #9D9D9D;
      transition: 0.2s;

    }

    &__back .cbar__item__text { color: #AD6BB8; }

    &__icon {

      max-width: 24px;
      min-height: 24px;
      margin-bottom: 3px;

      &_material {
        
        width: 26px;
        height: 26px;
        max-width: unset;
        background-color: #cccccc;
        background-size: 155%;
        border-radius: 5px;
        
      }

      &_module {

        width: 43px;
        max-width: unset;
        height: 43px;
        margin-bottom: -14px;
        position: relative;
        top: -8px;

        & + .cbar__item__text {

          position: relative;
          background: #fff;
          padding: 0 10px;

        }

      }

    }

  }

  &__anchor {

    position: fixed;
    z-index: 6;
    width: 50px;
    height: 5px;
    bottom: 51px;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    transition: 0.3s;
    margin: auto;

  }

  &--balkon {

    .cbar__anchor { 

      left: 291px;
      right: 0px;
      background-color: #f9a825;

    }

    .cbar__item__balkon .cbar__item__text { color: #F9A825; font-weight: 600; }

  }

  &--windows {

    .cbar__anchor { 

      left: 146px;
      right: 0px;
      background-color: #5dd8d0;

    }

    .cbar__item__windows .cbar__item__text { color: #1AA271; font-weight: 600; }

  }

  &--client {

    .cbar__anchor { 

      left: 0px;
      right: 0px;
      background-color: #44c342;

    }

    .cbar__item__client .cbar__item__text { color: #04C600; font-weight: 600; }

  }

  &--document {

    .cbar__anchor { 

      left: 0px;
      right: 148px;
      background-color: #96c7ff;

    }

    .cbar__item__document .cbar__item__text { color: #4F95E7; font-weight: 600; }

  }

}