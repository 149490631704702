.checkbox {

  margin: 16px 0;

  &__anchor {

    min-width: 24px;
    min-height: 24px;
    background-color: #F0F0F0;
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    
    svg { 
      position: relative;
      display: none; 
    }

  }

  &__text {

    margin-left: 11px;
    font-size: 14px;
    color: #434343;
    user-select: none;
    
  }

  &--checked &__anchor { 
    
    background-color: #58B22D;
    border-color: #64B93B;
    svg { display: block; }
  
  }

}