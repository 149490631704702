@import '../../../styles/_apx.scss';

.lowtide {

  width: 100%;
  height: 100%;
  height: $apx-336;
  margin: 0 0 $apx-10;

  &-container {
  
    margin: auto;
    margin-top: 0;
    width: $apx-375;
    height: $apx-300;
  
  }

  &-subtitle {
  
    color: #7ABB5C;
    font-weight: 500;
    font-size: $apx-14;
    line-height: $apx-16;
    font-family: Roboto;
    padding-left: $apx-10;
    margin: $apx-4 0 $apx-8;
  
  }

  &-block {

    margin: auto;
    width: $apx-375;
    height: $apx-240;
    background-color: #F9FFF9;
    background-image: url('./input-lowtide.svg');
    background-size: $apx-141;
    background-position: top $apx-61 left $apx-112;

    &__amount {
    
      top: 0;
      left: 0;
      height: 100%;

      &__number, &__size {
      
        font-family: Roboto;
        color: #25823F;
        font-weight: 400;
        font-size: $apx-21;
        line-height: $apx-25;
        top: -$apx-2;
        position: relative;
      
      }

      &__number {

        padding-left: $apx-1;

      }

      &__input, 
      &__input::placeholder {
      
        z-index: 3;
        width: $apx-88;
        height: $apx-22;
        border: unset;
        text-align: end;
        font-family: Roboto;
        color: #25823F;
        font-weight: 400;
        font-size: $apx-21;
        line-height: $apx-25;
        background-color: unset;
        outline: none !important;
      
      }

      &__first {
      
        top: $apx-40;
        right: $apx-146;
      
      }

      &__second {
      
        top: $apx-91;
        right: $apx-93;
      
      }

      &__third {
      
        top: $apx-200;
        right: $apx-145;
      
      }
    
    }

    &__total {

      margin: 0 -$apx-1 $apx-10 0;

      p {

        font-family: Roboto;
        color: #25823F;
        font-weight: 400;
        font-size: $apx-16;
        line-height: $apx-20;

      }

      &__length {
      
        margin-right: $apx-5;
        font-weight: 500 !important;

        &__typesize {
        
          margin-right: $apx-5; 
        
        }

        &__factor {
        
          width: $apx-88;
          height: $apx-16;
          text-align: start;
          font-family: Roboto;
          color: #25823F;
          font-weight: 400;
          font-size: $apx-16;
          line-height: $apx-16;
          background-color: unset;
          text-align: start;
          padding: 0;
          position: relative;
          top: 0;
          left: 0;

          p { height: 100%; }
        
        }
      
      }

    }

  }

}

.lowtide-block__amount__text__factor,
.lowtide-block__amount__input__factor,
.lowtide-block__amount__input__factor::placeholder {

  z-index: 3;
  width: $apx-88;
  height: $apx-16;
  border: unset;
  text-align: start;
  font-family: Roboto;
  color: #25823F;
  font-weight: 400;
  font-size: $apx-16;
  line-height: $apx-16;
  background-color: unset;
  padding: 0;
  position: relative;
  top: $apx-2;
  left: 0 !important;
  outline: unset !important;

}

@media screen and (max-width: 800px ) {

  .lowtide {

    height: $apx-m-336;
    margin: 0 0 $apx-m-10;
  
    &-container {
    
      width: $apx-m-375;
      height: $apx-m-300;
    
    }
  
    &-subtitle {
    
      font-size: $apx-m-14;
      line-height: $apx-m-16;
      padding-left: $apx-m-10;
      margin: $apx-m-4 0 $apx-m-8;
    
    }
  
    &-block {
  
      width: $apx-m-375;
      height: $apx-m-240;
      background-size: $apx-m-141;
      background-position: top $apx-m-61 left $apx-m-112;
  
      &__amount {
      
        top: 0;
        left: 0;
        height: 100%;
  
        &__number, &__size {
        
          font-size: $apx-m-21;
          line-height: $apx-m-25;
          top: -$apx-m-2;
        
        }
  
        &__number {
  
          padding-left: $apx-m-1;
  
        }
  
        &__input, 
        &__input::placeholder {
        
          width: $apx-m-88;
          height: $apx-m-22;
          font-size: $apx-m-21;
          line-height: $apx-m-25;
        
        }
  
        &__first {
        
          top: $apx-m-40;
          right: $apx-m-146;
        
        }
  
        &__second {
        
          top: $apx-m-91;
          right: $apx-m-93;
        
        }
  
        &__third {
        
          top: $apx-m-200;
          right: $apx-m-145;
        
        }
      
      }
  
      &__total {
  
        margin: 0 -$apx-m-1 $apx-m-10 0;
  
        p {
  
          font-size: $apx-m-16;
          line-height: $apx-m-20;
  
        }
  
        &__length {
        
          margin-right: $apx-m-5;
  
          &__typesize {
          
            margin-right: $apx-m-5; 
          
          }
  
          &__factor {
          
            width: $apx-m-88;
            height: $apx-m-16;
            font-size: $apx-m-16;
            line-height: $apx-m-16;
          
          }
        
        }
  
      }
  
    }
  
  }
  
  .lowtide-block__amount__text__factor,
  .lowtide-block__amount__input__factor,
  .lowtide-block__amount__input__factor::placeholder {
  
    width: $apx-m-88;
    height: $apx-m-16;
    font-size: $apx-m-16;
    line-height: $apx-m-16;
    top: $apx-m-2;
    left: 0 !important;
  
  }

}