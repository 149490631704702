.element {

  cursor: pointer;
  user-select: none;
  margin: 14px 0px;

  &__image {

    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: lightgray;
    background-size: cover !important;
    background-position: center !important;
    margin-right: 14px;

  }

  &__text { 
    
    font-size: 15px;
    color: #494545; 
  
  }

}