@import '../../../styles/_apx.scss';

.schema--inner {

  padding: 25px 65px 5px 65px !important;
  height: 320px !important;

  li { 
    
    & + li { margin-left: 135px; }

  }

  .window-anchor {

    position: absolute;
    left: 0;
    right: 0;
    top: -2px;
    margin: auto;
    z-index: 10;

  }

  .window-move-anchor { margin-left: -10px; }
  .window-rotate-anchor { margin-right: -10px; }

  .window_inner_textfield__text { user-select: none; }

  .window--mirrored {

    .window_inner_textfield__text,
    .window_inner_textfield__input {

      transform: rotateY(180deg);

    }

  }

}

.window--inner {

  width: unset !important;
  height: unset !important;

  &.window--selected {

    .selected_arrow {

      position: absolute;
      top: -50px !important;
      transform: rotate(180deg);

    }

  }

}

@media screen and ( max-width: 800px ) {

  .schema--inner {

    padding: 50px 85px 5px 85px !important;
    height: 320px !important;
  
    li { 
      
      & + li { margin-left: 135px; }
  
    }

  }

}