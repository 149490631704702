.balkonform__item {

  user-select: none;
  cursor: pointer;
  margin-bottom: 30px;

  &__image {

    width: 100%;
    height: calc( 190px - ( ( 190px / 100 ) * var( --dpi-zoom, 1 ) ) );
    background-color: #eeeeee;
    background-size: cover;
    border-radius: 11px;

  }

  &__name {

    margin-top: calc( 8px - ( ( 8px / 100 ) * var( --dpi-zoom, 1 ) ) );
    padding-left: 2px;
    font-weight: 500;
    font-size: calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ) );
    color: #434343;

  }

  &--selected {

    background: #fff4b0;
    border-radius: 10px;
    border-bottom-left-radius: 3px;

    .balkonform__item__name {

      text-decoration: underline;
      font-size: calc( 16px - ( ( 16px / 100 ) * var( --dpi-zoom, 1 ) ));
    }
    
  }

}