.balkon_row {

  cursor: pointer;
  user-select: none;
  display: flex;
  position: relative;
  border-bottom: 1px solid #EDEDED;
  padding: 9px 0px;
  //// padding: 16px 13px 13px 13px;
  
  &__photo {
   
    min-width: 59px;
    min-height: 59px;
    margin-right: 12px;
    border-radius: 8px;
    background-size: cover;

  }

  &__date{

    font-size: 10px;
    font-weight: 700;
    color: #616161;
    padding-top: 2px;

  }

  &__value{

    font-weight: 700;
    font-size: 17px;
    color: #7ABB5C;
    padding-top: 6px;

  }

  &__adress{

    font-size: 12px;
    color: #8D8D8D;
    padding-top: 3px;
  
  }

  .status{

    display: flex;
    position: absolute;
    top: 16px;
    right: 0;

    .meaning{ 

      font-size: 10px;
      padding-right: 5px;

    }

    .circle{

      border-radius: 100%;
      border: 1px solid #DBDBDB;
      width: 13px;
      height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;

      .dot{

        border-radius: 100px;
        width: 8px;
        height: 8px;

      }
      
    }

    &--wait {

      .meaning{ color: #F0AD00;}

      .dot{ 

        background: #FBBC19;
        border: 1px solid #FBBC19;

      }

    }

    &--deal {

      .meaning { color: #7ABB5C;}

      .dot {

        background: #7ABB5C;
        border: 1px solid #7ABB5C;

      }

    }

  }

}