//подключение apx и tailwind
@import '../../../styles/_apx.scss';

.window-partner-row {

  width: 100%;
  max-width: $apx-375;
  padding: $apx-18 $apx-30 $apx-20;
  border-top: $apx-2 solid #f6f6f6;

  &__company {
  
    font-weight: 500;
    font-size: $apx-18;
    line-height: $apx-20;
    padding-bottom: $apx-8;
  
  }

  &__owner {
  
    font-weight: 400;
    font-size: $apx-15;
    line-height: $apx-18;
    padding-bottom: $apx-16;
  
  }

  &__icons {

    &__icon {
    
      width: $apx-56;
      height: $apx-56;
      margin-right: $apx-13;
      border-radius: $apx-3;
      align-items: center;
      justify-content: center;
      box-shadow: 0vw 0vw 0.35vw 0.135vw rgba(128, 128, 128, 0.135);

      svg {
      
        width: $apx-28;
        height: $apx-28;
      
      }
    
    }
  
  }

}

@media screen and (max-width: 800px) {

  
.window-partner-row {

  width: 100%;
  max-width: unset;
  padding: $apx-m-18 $apx-m-6 $apx-m-20;
  border-top: $apx-m-1 solid #f6f6f6;

  &__company {
  
    font-size: $apx-m-18;
    line-height: $apx-m-20;
    padding-bottom: $apx-m-8;
  
  }

  &__owner {
  
    font-size: $apx-m-15;
    line-height: $apx-m-18;
    padding-bottom: $apx-m-16;
  
  }

  &__icons {

    &__icon {
    
      width: $apx-m-56;
      height: $apx-m-56;
      margin-right: $apx-m-13;
      border-radius: $apx-m-3;
      box-shadow: 0vw 0vw 0.35vw 0.135vw rgba(128, 128, 128, 0.135);

      svg {
      
        width: $apx-m-28;
        height: $apx-m-28;
      
      }
    
    }
  
  }

}

}