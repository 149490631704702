.textfield__copytoclipboard {

  &__btn {

    width: 30px;
    height: 30px;
    top: 0;
    bottom: 0;
    right: 7px;
    margin: auto;

    background: #FFF9F5;
    border: 1.5px solid #FFC535;
    border-radius: 5px;

  }

  &__copied_text {

    width: 100%;
    text-align: center;
    margin-top: -14px;
    font-size: 14px;
    color: green;

  }

}