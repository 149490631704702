.picker_target {

  position: absolute;
  z-index: 2;
  right: 17px;
  bottom: -100vh;
  opacity: 0; 
  transition: 0.5s;    
  transition-timing-function: cubic-bezier(0.82, -0.01, 1, 1); 

  &--opened { 

    bottom: 56px; 
    opacity: 1;
    transition-timing-function: ease-in-out; 
  
  }  

  &__option {
  
    position: relative;
    z-index: 2;
    background-size: cover;
    background-position: 50%;
    background-color: white;
    border: calc( 3px - ( ( 3px / 100 ) * var( --dpi-zoom, 1 ) ) ) solid #d9d9d9;
    margin-bottom: calc( 8px - ( ( 8px / 100 ) * var( --dpi-zoom, 1 ) ) );
    height: calc( 60px - ( ( 60px / 100 ) * var( --dpi-zoom, 1 ) ) );
    min-width: calc( 55px - ( ( 55px / 100 ) * var( --dpi-zoom, 1 ) ) );
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;

    &__name {

      transform: translate( calc( -50px - ( ( -50px / 100 ) * var( --dpi-zoom, 1 ) ) ) );
      opacity: 0;
      transition: 0.3s;
      transform-origin: left;
      position: absolute;
      z-index: 2;
      white-space: nowrap;
      right: 0;
      padding: calc( 10px - ( ( 10px / 100 ) * var( --dpi-zoom, 1 ) ) ) calc( 20px - ( ( 20px / 100 ) * var( --dpi-zoom, 1 ) ) );
      color: #fff;
      background: #282828db;

    }

    &:hover:not(.picker_target__option--is-active) {
      
      border: 3px solid #f6c6c6;

      .picker_target__option__name {

        
        opacity: 1;
        transform: translate(calc( -71px - ( ( -71px / 100 ) * var( --dpi-zoom, 1 ) ) ));

      }

    }

    &--is-active {

      border-right: calc( 3px - ( ( 3px / 100 ) * var( --dpi-zoom, 1 ) ) ) solid red;
      cursor: default;

      .picker_target__option__name {

        
        opacity: 1;
        transform: translate(calc( -71px - ( ( -71px / 100 ) * var( --dpi-zoom, 1 ) ) ));

      }

    }

    img {

      height: 100%;
      width: auto;
      pointer-events: none;
      
    }

  }

}