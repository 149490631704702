.priceinfo {

  user-select: none;
  position: absolute;
  z-index: 2;
  top: -10vh;
  left: 0; 
  right: 0;
  margin: 10px;
  color: #e2e2e2;
  font-family: Inter, Roboto;  
  opacity: 0;
  transition: 0.3s;
  transform: scale(0.8);

  &--opened {
   
    top: 52px;
    opacity: 1;
    transform: scale(1);

  }

  &__row {

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px; 

    &--empty {
      min-height: 10px;
      padding: unset;
    }

    & + .priceinfo__row:not(.priceinfo__row--empty) { 

      border-top: 1px solid #424242; 

    }

    div:first-child { margin-right: 20px; }

  }

  &__subcontainer {

    background-color: #252525f7;
    border-radius: 8px;
    overflow: hidden;
  }

}

@media screen and ( min-width: 650px ) {

  .priceinfo {

    top: -10vh;
    right: -50vw;
    left: unset;

    &--opened {
   
      top: 32px;
      right: 0;
  
    }

  }

}