// @import '../../../styles/_apx.scss';

.smeta {

  width: 100%;
  max-width: 100vw;
  // overflow-x: hidden;
  overflow: hidden;
  height: calc( 100% - 49px );
  bottom: 0;
  top: unset;
  padding: unset;

  hr {

    background-color: #dcdcdc;
    height: 1px;
    border: unset;

  }

  &__head {

    user-select: none;
    padding: 0 14px;
    height: 48px;

    &__title { 
      
      font-size: 18px;
      color: #4B4B4B;
    
    }
    
    &__btn {

      cursor: pointer;
      font-size: 14px;
      color: #56A033;

      svg { 

        margin-left: 8px;
        transition: 0.35s;
      
      }

      &--close {svg { transform: rotate( 224deg ); }}

    }

  }

  &__menu {

    overflow-y: auto;
    padding-bottom: 200px;
    max-height: 91vh;

    &__group {

      &__head {

        font-size: 18px;
        color: #4e4e4e;

      }

      &__inner {
        padding-left: calc( 30px - ( ( 30px / 100 ) * var( --dpi-zoom, 1 ) ));
      }

      &--opened {

        background-color: #d6ffd663;

        .smeta__menu__group__head { font-weight: 500; }

      }

    }

  }

  &__table {

    user-select: none;
    padding-bottom: 100px;

    &__head { border-top: 1px solid #d9d9d9; }

    &__content {

      overflow-y: auto;
      max-height: 73vh;
      padding-bottom: 120px;

    }

    &__head,
    &__row {

      font-size: 13px;
      display: grid;
      grid-template-columns: 1fr 90px 77px 29px;      
      border-bottom: 1px solid #d9d9d9;

      div:not(:last-child) { padding: 10px 5px; }
      div:first-child { padding-left: 14px; }

      div:last-child { 

        width: 24px;
        height: 26px;
        padding-right: 0px;

      }

      .text-right, .text-center { white-space: nowrap; }

    }

    &__accordeon {

      &__icon {

        width: 10px;
        height: 10px;
        // margin-left: 8px;
        margin-right: 8px;
        transition: 0.3s;
        
      }

      &__content {

        min-height: 0;
        max-height: 0;
        overflow: hidden;
        transition: 0.3s;

        &__container {
            
          padding-top: 2px;
          padding-bottom: 2px;
          padding-left: 27px;
          border-bottom: 1px solid #d9d9d9;

        }

      }

      &--opened {

        .smeta__table__accordeon__icon { transform: rotate(180deg); }

      }

    }

    &__material_info {

      margin: 7px 0;

      & + .smeta__table__material_info {

        border-top: 1px solid #d9d9d9;
        padding-top: 7px;

        .smeta__table__material_info__size { top: 7px !important; }

      }

      &__image {

        min-width: 36px;
        height: 36px;
        border-radius: 4px;
        margin-right: 9px;
        background-position: center !important;
        background-size: cover !important;

      }

      &__content { padding-right: 88px; }

      &__name {

        font-size: 14px;
        margin-top: 1px;
        
      }

      &__wall {

        font-size: 12px;
        margin-top: 3px;
        
      }

      &__size {

        top: 0px;
        bottom: 0px;
        margin: auto;
        right: 34px;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        
      }

    }

  }

  &__menu {

    &__element {

      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 68px;
      padding: 0 37px;
      font-size: 16px;
      color: #4B4B4B;
      border-top: 1px solid rgba(0, 0, 0, 0.06);

    }

    &__search {

      padding: 0 22px;

      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }

    }

  }

  &__scroll {

    max-height: calc(100vh - 160px);
    overflow-y: auto;
    padding-bottom: 20px;

  }

  &__glazing_job {

    .textfield {

      max-width: 150px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      right: 2px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse !important;

      &__title {

        font-size: 18px;
        margin-left: 9px;
        position: relative;
        top: 4px;

      }

      input { 
        
        text-align: right; 
        font-size: 18px;
      
      }

    }

  }

  .cupboard_size {

    .textfield {

      max-width: 96px;

      &__title {
        
        font-size: 15px;
        font-weight: 400;
        color: #383838;
        
      }
      
      input { 

        text-align: center; 
        background: #ffffffbf;
        border: 1px solid #cccccc;
        border-radius: 6px;

      }

      & + .textfield { margin-left: 20px; }

    }

  }

  &__smalltext {
    
    font-size: 14px;
    line-height: 16px;
    color: #434343;

  }

  &__contract_btn {

    width: 100%;
    padding: 10px 14px 18px;
    hr { margin: 8px 0; }
    p { font-size: 14px; }
    .balkon_settings__title { display: none; }
    .toggleslider { max-width: 150px; }
    .toggleslider__container { margin-bottom: unset; }

  }

}

@media screen and ( min-width: 650px ) {

  .smeta {

    max-width: 450px;
    height: 100%;

    &__table {

      cursor: pointer;
      height: calc(100% - 48px);
      padding-bottom: unset;

      &__head { 
        
        user-select: none;
        padding-right: 7px;
      
      }

      &__content { 

        max-height: calc(100% - 48px); 
        padding-bottom: unset;

      }
    }

  }

}