* { 
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-decoration: none;
}

html {
  -webkit-text-size-adjust: none;
	touch-action: manipulation;
}

html,
body,
#root {
  position: relative;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  outline: none;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  font-family: Roboto;
}

$mobile-breakpoint: 650px;

body {

  position: fixed;
  height: 100%;
  overflow: hidden;
  background: white;
  display: flex;
  justify-content: center;
  scroll-behavior: smooth;

}

#root { 
  
  max-width: 100vw;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  overflow-x: hidden;

}

.error { color: #f30000; }

h2 {

  margin-top: calc( 4px - ( ( 4px / 100 ) * var( --dpi-zoom, 1 ) ) );
  margin-bottom: calc( 18px - ( ( 18px / 100 ) * var( --dpi-zoom, 1 ) ) );
  font-size: calc( 18px - ( ( 18px / 100 ) * var( --dpi-zoom, 1 ) ) );
  padding: unset;
  font-weight: 500;
  color: #6F6F6F;

}

p {
  margin: unset;
  padding: unset;
}

::-webkit-scrollbar {

  width: 6px;
  height: 6px; 

}

::-webkit-scrollbar-track {

  -webkit-border-radius: 10px;
  border-radius: 10px;
  // background: unset;
  background: #e7e7e7;

}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #9dcf86;
  border: 1px solid #92c17c;
  width: 6px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; // Yeah, yeah everybody write about it
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
}

@import "./tailwind";

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');