.pricebar {

  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 49px;
  background: #252525;
  user-select: none;

  &__wrapper {

    width: 100%;
    // max-width: 700px;
    padding: 0 11px 0px 11px;

  }

  &__size {

    position: relative;
    top: -1px;
    color: #fff;

    &__text {

      min-width: 86px;
      font-size: 12px;
      font-weight: 400;

    }

    &__value {

      min-width: 38px;
      font-weight: 700;
      font-size: 14px;
      text-align: right;
      
    }

    &__m2 {

      padding-left: 4px;
      font-size: 14px;

      span {

        position: relative;
        top: -4px;
        font-size: 10px;
        font-weight: 500;

      }
      
    }

  }

  &__price {

    cursor: pointer;
    font-weight: 700;
    font-size: 24px;
    text-align: right;
    color: #71E13C;

    span { padding-left: 8px; }

  }

}

@media screen and ( min-width: 650px ) {

  .pricebar {

    height: 29px;
    background: unset;

    &__wrapper { padding: unset; }

    &__size {
      
      display: flex;
      height: 100%;
      padding: 7px 17px;
      border-bottom-right-radius: 10px;
      background: #252525;

      &__text,
      &__value { min-width: unset; }
      &__value { padding-left: 4px; }

    }

    &__size__row + .pricebar__size__row { margin-left: 30px; }
    
    &__price {

      font-size: 16px;
      display: flex;
      height: 100%;
      padding: 7px 11px 7px 18px;
      border-bottom-left-radius: 10px;
      background: #252525;

    }

  }

}