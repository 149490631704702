.balkonform {

  display: flex;
  padding-top: 13px;
  padding-bottom: 100px;
  width: 100%;
  max-width: 430px;
  margin: 0 auto;

  &__items {

    display: grid;
    grid-template-columns: 45% 45%;
    grid-column-gap: 7%;
    justify-content: center;
    
  }

  .tip {

    font-size: calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ) );
    text-align: center;
    color: #7ABB5C;
    margin-bottom: calc( 20px - ( ( 20px / 100 ) * var( --dpi-zoom, 1 ) ) );

  }

  .inputs {
  
    .textfield {
  
      margin-bottom: calc( 5px - ( ( 5px / 100 ) * var( --dpi-zoom, 1 ) ) );
  
      &__title { 
      
        min-height: calc( 26px - ( ( 26px / 100 ) * var( --dpi-zoom, 1 ) ) );
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      
      }

      input { text-align: center; }
  
    }

    .toggleslider {

      padding-left: 14px;

      &__title {

        font-weight: 400;
        font-size: calc(14px - 0.14px * var(--dpi-zoom, 1));
        color: #4E4E4E;
        min-height: calc(26px - 0.26px * var(--dpi-zoom, 1));
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 14px;

      }

    }
  
  }

  hr.small { 

    width: 35%; 
    margin: calc( 10px - ( ( 10px / 100 ) * var( --dpi-zoom, 1 ) ) ) auto; 
    background-color: #7ABB5C; 

  }

  &--2 {

    .flex.inputs {

      text-align: center;
  
      .textfield {
        
        &__title { 
  
          min-height: 22px; 
          color: #565656; 
          font-weight: 500; 
  
        }
      
      }
  
      input { text-align: center; }
  
      .toggleslider { 
        
        margin-left: 10px; 
        
        &__title { 
          
          min-height: 22px; 
          color: #565656; 
          font-weight: 500; 
          margin-bottom: 13px; 
          font-size: calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ));
  
        }
  
        &__list__element {
  
          font-size: 16px;
          font-size: 17px;
          font-weight: 400;
          &:not(.toggleslider__list__element--selected) { color: #616161; }
  
        }
  
      }
  
    }

  }

  &--3 {

    .windows_settings { padding-bottom: unset !important; }

    .balkon_settings__title {

      background: unset !important;
      font-size: 17px !important;
      text-align: left !important;
      padding: 4px 16px !important;
      margin-bottom: unset !important;
      border: unset !important;

    }

  }

}