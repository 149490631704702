@import '../../../styles/_apx.scss';

.visor {

  width: 100%;
  height: 100%;
  height: $apx-336;
  margin: 0 0 $apx-10;

  &-container {
  
    margin: auto;
    margin-top: 0;
    width: $apx-375;
    height: $apx-300;
  
  }

  &-subtitle {
  
    color: #7ABB5C;
    font-weight: 500;
    font-size: $apx-14;
    line-height: $apx-16;
    font-family: Roboto;
    padding-left: $apx-10;
    margin: $apx-4 0 $apx-8;
  
  }

  &-block {

    margin: auto;
    width: $apx-375;
    height: $apx-240;
    background-color: #F9FFF9;      
    background-size: $apx-227;
    background-position: top $apx-44 left $apx-46;
    background-image: url(./input-visor.svg);

    &__image {

      margin: $apx-66 $apx-100 $apx-26 $apx-40;

      &__img {

        width: 100%;
        height: 100%;
        width: $apx-240 !important;
        height: $apx-135 !important;
        position: relative;
        top: 35px;

      }

    }

    &__amount {
    
      height: 100%;
      left: 0;
      top: 0;

      &__number, &__size {
      
        font-family: Roboto;
        color: #25823F;
        font-weight: 400;
        font-size: $apx-21;
        line-height: $apx-25;
        top: -$apx-2;
        position: relative;
      
      }

      &__number {

        padding-left: $apx-1;

      }

      &__size { user-select: none; }

      &__input, 
      &__input::placeholder {
      
        z-index: 3;
        width: $apx-88;
        height: $apx-22;
        border: unset;
        text-align: end;
        font-family: Roboto;
        color: #25823F;
        font-weight: 400;
        font-size: $apx-21;
        line-height: $apx-25;
        background-color: unset;
        outline: none !important; 

        &:hover,
        &:focus { outline: none; }
      
      }

      &__first {
      
        top: $apx-13;
        right: $apx-227;
      
      }

      &__second {
      
        top: $apx-51;
        right: $apx-101;
      
      }

      &__third {
      
        top: $apx-82;
        right: $apx-63;
      
      }

      &__fourth {
      
        top: $apx-173;
        right: $apx-113;
      
      }
    
    }

    &__total {

      margin: 0 -$apx-1 $apx-10 0;

      p {

        font-family: Roboto;
        color: #25823F;
        font-weight: 400;
        font-size: $apx-16;
        line-height: $apx-20;

      }

      &__length {
      
        margin-right: $apx-5;
        font-weight: 500 !important;

        &__typesize {
        
          margin-right: $apx-5; 
        
        }

        &__factor {
        
          width: $apx-88;
          height: $apx-16;
          text-align: start;
          font-family: Roboto;
          color: #25823F;
          font-weight: 400;
          font-size: $apx-16;
          line-height: $apx-16;
          background-color: unset;
          text-align: start;
          padding: 0;
          position: relative;
          top: 0;
          left: $apx-166;

          p { height: 100%; }
        
        }
      
      }

    }

  }

}

.visor-block__amount__input__factor,
.visor-block__amount__input__factor::placeholder {

  z-index: 3;
  width: $apx-88;
  height: $apx-16;
  border: unset;
  text-align: start;
  font-family: Roboto;
  color: #25823F;
  font-weight: 400;
  font-size: $apx-16;
  line-height: $apx-16;
  background-color: unset;
  margin: 0 auto;
  padding: 0 auto;
  position: relative;
  top: $apx-2;
  left: -$apx-34;
  outline: unset !important;

}

@media screen and (max-width: 800px ) {
  
  .visor {

    height: $apx-m-336;
    margin: 0 0 $apx-m-10;

    &-container {
    
      width: $apx-m-375;
      height: $apx-m-300;
    
    }

    &-subtitle {
    
      font-size: $apx-m-14;
      line-height: $apx-m-16;
      padding-left: $apx-m-10;
      margin: $apx-m-4 0 $apx-m-8;
    
    }

    &-block {

      margin: auto;
      width: $apx-m-375;
      height: $apx-m-240;  
      background-size: $apx-m-227;
      background-position: top $apx-m-44 left $apx-m-46 !important;

      &__image {

        margin: $apx-m-66 $apx-m-100 $apx-m-26 $apx-m-40;

        &__img {

          width: $apx-m-240 !important;
          height: $apx-m-135 !important;

        }

      }

      &__amount {

        &__number, &__size {
        
          font-size: $apx-m-21;
          line-height: $apx-m-25;
          top: -$apx-m-2;
        
        }

        &__number { padding-left: $apx-m-1; }

        &__input, 
        &__input::placeholder {
        
          width: $apx-m-88;
          height: $apx-m-22;
          font-size: $apx-m-21;
          line-height: $apx-m-25;
        }

        &__first {
        
          top: $apx-m-13;
          right: $apx-m-227;
        
        }

        &__second {
        
          top: $apx-m-51;
          right: $apx-m-101;
        
        }

        &__third {
        
          top: $apx-m-82;
          right: $apx-m-63;
        
        }

        &__fourth {
        
          top: $apx-m-173;
          right: $apx-m-113;
        
        }
      
      }

      &__total {

        margin: 0 -$apx-m-1 $apx-m-10 0;

        p {

          font-size: $apx-m-16;
          line-height: $apx-m-20;

        }

        &__length {
        
          margin-right: $apx-m-5;

          &__typesize {
          
            margin-right: $apx-m-5; 
          
          }

          &__factor {
          
            width: $apx-m-88;
            height: $apx-m-16;
            font-size: $apx-m-16;
            line-height: $apx-m-16;
            left: 0;
          
          }
        
        }

      }

    }

  }

  .visor-block__amount__input__factor,
  .visor-block__amount__input__factor::placeholder {

    width: $apx-m-88;
    height: $apx-m-16;
    font-size: $apx-m-16;
    line-height: $apx-m-16;
    top: $apx-m-2;
    left: 0;

  }
  
}