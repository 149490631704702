.header {

  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 52px;
  padding: 0 20px 8px;
  border-radius: 0 0 calc( 16px - ( ( 16px / 100 ) * var( --dpi-zoom, 1 ) ) ) calc( 16px - ( ( 16px / 100 ) * var( --dpi-zoom, 1 ) ) );
  background: #7ABB5C;
  
  &__logo {

    font-weight: 700;
    font-size: calc( 21px - ( ( 21px / 100 ) * var( --dpi-zoom, 1 ) ) );
    color: #fff;

  }

  &__burger {

    width: calc( 26px - ( ( 26px / 100 ) * var( --dpi-zoom, 1 ) ) );
    height: calc( 19px - ( ( 19px / 100 ) * var( --dpi-zoom, 1 ) ) );
    background-image: url(./burger.svg);

  }

  &__menu {

    position: fixed;
    z-index: 5;
    width: 100%;
    height: calc( 100vh - 40px );
    top: 40px;
    left: 0;
    padding-top: 12px;
    background: #7ABB5C;

    &__row {  

      width: 100%;
      padding: 17px 27px;
      color: #fff;
      font-size: 17px;

      svg { 

        width: 26px;
        min-width: 26px;
        margin-right: 20px;

      }

      border-top: 1px solid rgba(237, 237, 237, 0.2);

    }

  }

}