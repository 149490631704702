.selectfield {

  position: relative;
  width: 100%;
  max-width: 382px;
  margin-bottom: 22px;
  cursor: pointer;

  &__title {

    margin-bottom: 6px;
    padding-left: 3px;
    font-size: 16px;
    font-weight: 400;
    color: #383838;

    & ~ .selectfield__list { top: 63px !important; }

  }

  &__field {

    position: relative;
    width: 100%;
    padding: 9px 12px;
    font-size: 16px;
    color: #595959;
    background: #ffffffbf;
    border: 1px solid #cccccc;
    border-radius: 6px;

    &--placeholder { color: lightgray; }

    &__object {

      color: #616161;
      padding-left: 6px;

    }

    &__arrow {

      position: absolute;
      top: -1px;
      bottom: 0;
      right: 14px;
      margin: auto;
      width: 14px;
      height: 14px;
      transition: 0.3s;
      background-image: url(./triangle-small.svg);

    }

  }

  &__list {

    user-select: none;
    color: #4f4f4f;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    position: absolute;
    width: 98%;
    top: 40px;
    left: 0;
    right: 0;
    margin: auto;    
    z-index: 5;
    border: 1px solid #cccccc;
    border-top: unset;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background: #ffffffe6;
    opacity: 0;

    &__item {

      cursor: pointer;
      padding: 10px 15px;
      font-size: 17px;
      font-size: 15px;
      border-top: 1px solid #e3e3e3;
      transition: 0.2s;

      &:hover {

        background: #e0ffd4f5;
        color: #1c6003;

      }

    }

  }

  &--opened { 
    
    .selectfield__field__arrow { transform: rotate3d(1, 0, 0, 180deg ); } 
    .selectfield__list { opacity: 1; } 
  
  }

  // ::placeholder {

  //   font-size: 16px;
  //   color: #4B6379;

  // }

}

.popup .selectfield {

  max-width: unset;
  margin-bottom: 18px;
  
  &__field {

    padding: 9px 14px 10px;
    font-size: 17px;
    border-radius: 10px;

  }

  &__title { color: var(--color-popup-textgray); }

}

.popup__area .selectfield__field { background: rgba(0, 0, 0, 0.04); }