//подключение apx и tailwind
@import '../../../../styles/_apx.scss';

.balcon-block-one {

  height: auto;
  min-width: 185px;
  
  svg {
  
    width: 100%;
    height: auto;
  
  }

  .window_inner_textfield {
  
    top: 0;
    width: 60px;
    position: absolute;

    &--A {
    
      top: -33px;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
    
    }

    &--B {
    
      top: 50px;
      left: -5px;
      text-align: right;
    
    }

    &--C {
    
      top: 50px;
      right: -63px;
      text-align: left;
    
    }

    &--D {
    
      top: 155px;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
    
    }

    &__text {
    
      z-index: 3;
      width: 100%;
      color: #c5c5c5;
      font-family: Roboto;
      font-size: 22px;
      line-height: 24px;
      font-weight: 500 !important;

      &--A { color: #FFA800; }
      &--B { color: #1896DC; }
      &--C { color: #BB785C; }
      &--D { color: #BB5CA0; }
      
    }

    &__input {
    
      z-index: 3;
      width: 100%;
      border: unset;
      font-family: Roboto;
      color: #c5c5c5;
      font-size: 22px;
      line-height: 24px;
      font-weight: 500 !important;
      background-color: unset;
      outline: none !important;

      &--A {
      
        left: 0px;
        top: -1px;
        text-align: center;
        color: #FFA800 !important;
        &::placeholder { color: #FFA800 !important; }
      
      }

      &--B {
      
        text-align: right;
        left: 2px;
        top: -1.5px;
        color: #1896DC !important;
        &::placeholder { color: #1896DC !important; }
      
      }

      &--C {
      
        text-align: left;
        top: -1px;
        left: -2px;
        color: #BB785C !important;
        &::placeholder { color: #BB785C !important; }
      
      }

      &--D {

        text-align: center;
        top: -0.5px;
        left: 0px;
        color: #BB5CA0 !important;
        &::placeholder { color: #BB5CA0 !important; }
      
      }

    }
    
  }
  
}

.window--mirrored {

  .balcon-block-one {

    .window_inner_textfield {

      width: 60px;

      &--A { top: -32px !important; }
      &--B { left: -2px !important; text-align: left !important; }
      &--C { right: -63px !important; text-align: right !important; }
 
    }

    .window_inner_textfield__input {

      &--A { top: -0.5px !important; }
      &--B { top: -1px !important; left: 2px !important; text-align: left !important; }
      &--C { left: -1px; text-align: right !important; }

    }

  }

}

//адаптив mobile
@media screen and ( max-width: 800px ) {

  .balcon-block-one {

    width: 220px;
  
    .window_inner_textfield {
  
      width: 80px;

      &--A {
      
        top: -39px;
      
      }
  
      &--B {
      
        top: 50px;
        left: -13px;
      
      }
  
      &--C {
      
        top: 50px;
        right: 8px;
        text-align: right;
      
      }
  
      &--D {
      
        top: 175px;
      
      }
  
      &__text {
      
        width: 100%;
        height: 65px;
        font-size: 26px;
        line-height: 30px;
        
      }
  
      &__input {
      
        width: 100%;
        font-size: 26px;
        line-height: 30px;
        &--B { top: -1px !important; left: 2px !important; text-align: right; }
        &--C { left: 2px !important; text-align: right; }
        &--D { top: -1px; right: 0.25px !important; }
  
      }
      
    }
    
  }

  .window--mirrored {

    .balcon-block-one {
  
      .window_inner_textfield {

        width: 80px;
  
        &--A { top: -36px !important; }
        &--B { left: -13px !important; }
        &--C { left: 225px !important; text-align: right !important; }
        &--D { top: 180px !important; left: 3px !important; }
        
      }
  
      .window_inner_textfield__input {
        
        &--B { text-align: left !important; margin-left: -0.5px !important; }
        &--C { left: -2.5px !important; text-align: right !important; }
        &--D { top: -0.5px !important; }
  
      }
  
    }
  
  }

}