.sign {

  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  
  &__big_logo {

    margin: calc( 72px - ( ( 72px / 100 ) * var( --dpi-zoom, 1 ) ) ) auto calc( 52px - ( ( 52px / 100 ) * var( --dpi-zoom, 1 ) ) );
    font-weight: 900;
    font-size: calc( 64px - ( ( 64px / 100 ) * var( --dpi-zoom, 1 ) ) );
    line-height: calc( 75px - ( ( 75px / 100 ) * var( --dpi-zoom, 1 ) ) );
    text-align: center;
    color: #B2D3B9;

    span {

      display: block;
      font-size: calc( 30px - ( ( 30px / 100 ) * var( --dpi-zoom, 1 ) ) );
      line-height: calc( 35px - ( ( 35px / 100 ) * var( --dpi-zoom, 1 ) ) );
      text-align: center;

    }

  }

  .button { margin: calc( 30px - ( ( 30px / 100 ) * var( --dpi-zoom, 1 ) ) ) 0 calc( 25px - ( ( 25px / 100 ) * var( --dpi-zoom, 1 ) ) )}

  &__recovery_pass {

    cursor: pointer;
    user-select: none;
    position: relative;
    font-weight: 500;
    font-size: calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ) );
    line-height: calc( 16px - ( ( 16px / 100 ) * var( --dpi-zoom, 1 ) ) );
    text-align: center;
    color: #7ABB5C;

    span::after {

      content: "";
      display: block;
      position: absolute;
      width: calc( 118px - ( ( 118px / 100 ) * var( --dpi-zoom, 1 ) ) );
      right: 0;
      left: 0;
      bottom: -2px;
      margin: auto;
      height: 1px;
      background: #7ABB5C;

    }

  }

}