@import '../../../styles/_apx.scss';

.windows_settings {

  padding-top: 14px;
  padding-bottom: 80px;

  .toggleslider { margin: 22px 0; }

  .checkboxes {

    max-width: 250px;
    margin: 22px 0;

  }

  .textfield {

    &__title {

      margin-bottom: 6px;
      padding-left: 3px;
      font-size: 16px;
      font-weight: 400;
      color: #383838;

    }

  }

  .visor {

    width: calc( 100% + 32px );
    margin-left: -$apx-16;

  }

  .flex.inputs {
    .textfield__title,
    input { text-align: center; }
  }

}

@media screen and (max-width: 800px) {

  .windows_settings {

    .visor {

      width: calc( 100% + $apx-m-32 );
      margin-left: -$apx-m-16;
  
    }

  }

}