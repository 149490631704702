//подключение apx и tailwind
@import '../../../../styles/_apx.scss';

.balcon-block-two {

  height: auto;
  min-width: 150px;

  svg {
  
    width: 100%;
    height: auto;
  
  }

  .window_inner_textfield {
  
    top: 0;
    width: 60px;
    position: absolute;

    &--A {
    
      top: -33px;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
    
    }

    &--B {
    
      top: 50px;
      left: 6px;
      text-align: left;
    
    }

    &--C {
    
      top: 50px;
      right: -66px;
      text-align: left;
    
    }

    &--D {
    
      top: 150px;
      left: 86px;
      text-align: center;
    
    }

    &__text {
    
      z-index: 3;
      width: 100%;
      color: #c5c5c5;
      font-family: Roboto;
      font-size: 22px;
      line-height: 24px;
      font-weight: 500 !important;

      &--A { color: #FFA800; }
      &--B { color: #1896DC; }
      &--C { color: #BB785C; }
      &--D { color: #BB5CA0; }
      
    }

    &__input {
    
      z-index: 3;
      width: 100%;
      border: unset;
      position: absolute;
      font-family: Roboto;
      color: #c5c5c5;
      font-size: 22px;
      line-height: 24px;
      font-weight: 500 !important;
      background-color: unset;
      outline: none !important;

      &--A {

        top: -1px;
        left: 0px;
        text-align: center;
        color: #FFA800 !important;
        &::placeholder { color: #FFA800 !important; }
      
      }

      &--B {
      
        top: -1px;
        left: -2px;
        text-align: left;
        color: #1896DC !important;
        &::placeholder { color: #1896DC !important; }
      
      }

      &--C {

        top: -1px;
        left: -2.25px;
        text-align: left;
        color: #BB785C !important;
        &::placeholder { color: #BB785C !important; }
      
      }

      &--D {
      
        top: -1px;
        left: 0px;
        text-align: center;
        color: #BB5CA0 !important;
        &::placeholder { color: #BB5CA0 !important; }
      
      }

    }
    
  }
  
}

.window--mirrored {

  .balcon-block-two {

    .window_inner_textfield {

      width: 60px;

      &--A { top: -32px !important; }
      &--B { left: 8px !important; text-align: right !important; }
      &--C { left: 155px !important; text-align: right !important; }
      &--D { left: 88px !important; }

      &__input {

        &--A { top: -0.5px !important; }
        &--B { left: -2px !important; text-align: right !important; }
        &--C { left: -2px !important; text-align: right !important; }
        &--D { top: -1px !important; left: 0 !important; }

      }

    }

  }

}

//адаптив mobile

@media screen and ( max-width: 800px ) {

  .balcon-block-two {

    width: 180px;
  
    .window_inner_textfield {
  
      width: 80px;

      &--A {
      
        top: -39px;
        left: -20px;
      
      }
  
      &--B {
      
        top: 50px;
        left: 10px;
      
      }
  
      &--C {
      
        top: 50px;
        right: -86px;
      
      }
  
      &--D {
      
        top: 177px;
        left: 88px;
      
      }
  
      &__text {
      
        width: 100px;
        height: 65px;
        font-size: 26px;
        line-height: 30px;
        
      }
  
      &__input {

        font-size: 26px;
        line-height: 30px;  
        &--A { left: 10px !important; }
        &--B { left: -2px !important; }
        &--C { right: -98px !important; }
        &--D { left: 10px !important; }
  
      }
      
    }
    
  }

  .window--mirrored {

    .balcon-block-two {
  
      .window_inner_textfield {

        width: 80px;
  
        &--B { left: -70px;  text-align: right !important; }
        &--C { left: 185px !important; text-align: right !important; }
        &--D { left: 91px !important; }
         
      }

      .window_inner_textfield__input {
        
        &--B { left: -2px !important; text-align: right !important; }
        &--C { left: -1.5px !important; text-align: right !important; }
        &--D { left: 10px !important; }
  
      }
  
    }
  
  }

}