.button {

  position: relative;
  width: 100%;
  min-width: 206px;
  margin: calc( 10px - ( ( 10px / 100 ) * var( --dpi-zoom, 1 ) ) ) 0;  
  padding: calc( 12px - ( ( 12px / 100 ) * var( --dpi-zoom, 1 ) ) ) calc( 27px - ( ( 27px / 100 ) * var( --dpi-zoom, 1 ) ) ) calc( 13px - ( ( 13px / 100 ) * var( --dpi-zoom, 1 ) ) );
  font-size: calc( 18px - ( ( 18px / 100 ) * var( --dpi-zoom, 1 ) ) );
  font-weight: 500;  
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  border: unset;

  & + .button { margin-top: 0; }

  &.disabled {

    opacity: 0.4;
    pointer-events: none;
    font-weight: 500;

  }

  &__icon {

    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    margin: auto;
    width: calc( 30px - ( ( 30px / 100 ) * var( --dpi-zoom, 1 ) ) );
    font-size: calc( 30px - ( ( 30px / 100 ) * var( --dpi-zoom, 1 ) ) );
    line-height: 0;

    & + .button__text { 

      width: 100%;
      padding: 0 calc( 9px - ( ( 9px / 100 ) * var( --dpi-zoom, 1 ) ) ) 0 calc( 17px - ( ( 17px / 100 ) * var( --dpi-zoom, 1 ) ) );

    }

  }

  &:hover,
  &:focus { outline: none; }

  &--green {

    background: #7ABB5C;
    color: #FFF;

    & + .button--gray { margin-top: 14px; }

  }

  &--red {

    background: #e25a5a;
    color: #FFF;

  }

  &--yellow {

    background: #ffb50e;
    text-shadow: 0px 2px 6px #0000003d;
    color: #FFF;

  }

  &--gray {

    background: #a8a8a8;
    text-shadow: 0px 2px 6px #0000003d;
    color: #FFF;

  }

}

.flex { 
  
  .button {
    
    margin-bottom: unset;

    & + .button { margin-left: calc( 19px - ( ( 19px / 100 ) * var( --dpi-zoom, 1 ) ) ); }

  }

}