.balkon_scheme_public {

  align-items: center;

  .windows_constructor { width: 100%; }
  .windows_settings {
    max-width: 550px;
    padding: 10px;

    .textfield { max-width: unset; }
  }

}