.sill_settings {

  flex-grow: 1;
  padding: 16px 0;

  .flex.inputs {

    text-align: center;

    .selectfield {

      margin: 0 10px;

      &__title {

        min-height: 17px; 
        color: #565656; 
        font-weight: 500; 
        margin-bottom: 13px; 
        font-size: calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ));

        & ~ .selectfield__list { top: 68px !important; }

      }

      &__field {
        
        justify-content: center;
        padding-right: 33px;
        border: 1px solid #F1F1F1; 
      
      }

      &__list { opacity: 0; }
      &--opened .selectfield__list { opacity: 1; }

    }

    .textfield {
      
      &__title { 

        min-height: 22px; 
        color: #565656; 
        font-weight: 500; 

      }     
    
    }

    input { text-align: center; }

    .toggleslider { 
      
      margin-left: 10px; 
      
      &__title { 
        
        min-height: 22px; 
        color: #565656; 
        font-weight: 500; 
        margin-bottom: 13px; 
        font-size: calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ));

      }

      &__list__element {

        font-size: 16px;
        font-size: 17px;
        font-weight: 400;
        &:not(.toggleslider__list__element--selected) { color: #616161; }

      }

    }

  }

}