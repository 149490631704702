.textfield {

  width: 100%;
  max-width: 440px;
  margin-bottom: calc( 22px - ( ( 22px / 100 ) * var( --dpi-zoom, 1 ) ) );

  &__title {

    margin-bottom: calc( 8px - ( ( 8px / 100 ) * var( --dpi-zoom, 1 ) ) );
    padding-left: calc( 3px - ( ( 3px / 100 ) * var( --dpi-zoom, 1 ) ) );
    font-weight: 400;
    font-size: calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ) );
    color: #4E4E4E;

  }

  input, textarea {

    width: 100%;
    padding: calc( 13px - ( ( 13px / 100 ) * var( --dpi-zoom, 1 ) ) ) calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ) );
    font-size: calc( 18px - ( ( 18px / 100 ) * var( --dpi-zoom, 1 ) ) );
    color: #6F6F6F;
    background: #FDFDFD;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 7px;

    &:hover,
    &:focus { outline: unset; }

  }

  ::placeholder {

    font-size: calc( 16px - ( ( 16px / 100 ) * var( --dpi-zoom, 1 ) ) );
    color: #969696;

  }

  &--readOnly {

    input, textarea {

      color: #212121;
      background: unset;
      border: unset;
      padding: 3px 2px 7px;
      font-size: 16px;
      border-bottom: 1px solid #f2f2f2;
      border-radius: 0px;

    }

  }

  &--wrong {

    .textfield__title { color: #df0000; }

    input, textarea {

      border: 2px solid red;
      background: #fff2f2;

    }

  }

  &--icon {

    svg {

      margin-right: 10px;
      pointer-events: none;
      
    }

    input { padding-right: 40px; }

  }

}

.flex > .textfield + .textfield { margin-left: calc( 16px - ( ( 16px / 100 ) * var( --dpi-zoom, 1 ) ) ); }

.popup .textfield {

  max-width: unset;
  margin-bottom: 18px;
  
  input {

    padding: calc( 9px - ( ( 9px / 100 ) * var( --dpi-zoom, 1 ) ) ) calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ) ) calc( 10px - ( ( 10px / 100 ) * var( --dpi-zoom, 1 ) ) );
    font-size: calc( 17px - ( ( 17px / 100 ) * var( --dpi-zoom, 1 ) ) );
    border-radius: calc( 10px - ( ( 10px / 100 ) * var( --dpi-zoom, 1 ) ) );
    background: rgba(0, 0, 0, 0.04);
    color: var(--color-textgray-input);

  }

  &__title { color: var(--color-popup-textgray); }

  ::placeholder { color: var(--color-placeholder); }

}

.resetpassword__text {
  
  user-select: none;
  margin-top: -16px;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 6px;
  margin-bottom: 17px;
  // margin-top: -$apx-16;
  // font-size: $apx-18;
  // padding: $apx-8 $apx-16;
  // border-radius: $apx-6;
  // margin-bottom: $apx-17;
  color: #5f411a;
  background: #fff4e0;

  span { 

    cursor: pointer;
    font-size: inherit; 
    text-decoration: underline; 
    color: #e26755;
    font-weight: 500;

    &:hover { color: #ef7462; }

  }

}

.generate_pass {

  margin-top: -18px;
  margin-bottom: calc( 22px - ( ( 22px / 100 ) * var( --dpi-zoom, 1 ) ));

  .textfield { margin-bottom: unset; }

  .button {

    max-width: 40%;
    min-width: unset;
    margin-top: unset;
    margin-left: 12px;

  }

}